import { Button, Center, LightMode, SimpleGrid } from "@chakra-ui/react";
import React from "react";
import { FaFacebook, FaYoutube } from "react-icons/fa";
import { useLocation } from "wouter";


export const Buttons = () => {
    const [location, setLocation] = useLocation();
    return (
        <Center>
            <LightMode>
                <SimpleGrid columns={[2, 2, 4]} spacing={4}>
                    {location === "/" ? null : <Button colorScheme="teal" variant="solid" onClick={() => setLocation("/")} size={"md"}>
                        Home
                    </Button>}
                    {location === "/donate" ? null :<Button colorScheme="teal" variant="solid" onClick={() => setLocation("/donate")} size={"md"}>
                        Donate
                    </Button>}
                    {location === "/schedule" ? null :<Button colorScheme="teal" size={"md"} onClick={() => setLocation("/schedule")}>
                        Schedule
                    </Button>}
                    <Button colorScheme="facebook" leftIcon={<FaFacebook />} variant="solid" onClick={() => window.open("https://www.facebook.com/SAChristmasBasket/")} size={"md"}>
                        Facebook
                    </Button>
                    <Button colorScheme="red" leftIcon={<FaYoutube />} onClick={() => window.open("https://www.youtube.com/channel/UC_Gugt356Xy0Jh4d6_UJkkQ")} size={"md"}>
                        YouTube
                    </Button>
                </SimpleGrid>
            </LightMode>
        </Center>
    )
}