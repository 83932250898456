import { Box, Button, Center, Flex, Grid, Link, SimpleGrid, Spacer, Text } from "@chakra-ui/react"
import React from "react"
import { Buttons } from "../components/Buttons"
// import { DonateButton } from "../components/DonateButton"
import { Title } from "../components/Title"


export const Donate = () => (
  <Box textAlign="center" fontSize="xl" bgGradient="linear(to-tr, #b92b27, #1565C0)" width="100%">
    <Grid minH="100vh" p={3}>
      <Title />
      <SimpleGrid columns={1} spacing={10} p={[5, 10, 25]} position="relative" zIndex="10">
        <Center>
          <Box bg="red.500" p={3} borderRadius="lg" width={["100%", "95%", "60%"]}>
            <Flex flexDirection="column" p={[5, 10]}>
              <Text fontWeight="extrabold" fontSize={["lg", "xl", "2xl"]}>Pledges and Donations:</Text>
              <Spacer />
              <Text fontSize={["md", "lg", "xl"]}>Pledges can be made online through PayPal or by phone:&nbsp;
                <Link color="#000000" href="tel:605-554-0553" fontWeight="bold">(605) 554-0553</Link>
                &nbsp;or by email:&nbsp;
                <Link color="#000000" href="mailto:pledge@christmasbasket.us" fontWeight="bold">pledge@christmasbasket.us</Link>
                &nbsp;
              </Text>
              <Spacer />
              {/* <DonateButton /> */}
              <form target="_blank" action="https://www.paypal.com/cgi-bin/webscr" method="post">
                <input type="hidden" name="cmd" value="_s-xclick" />
                <input type="hidden" name="hosted_button_id" value="X6GH4LSH5Z2DA" />
                {/* <input type="image" src="Paypal.png" name="submit" alt="PayPal - The safer, easier way to pay online!" width="50%"/> */}
                <Button type="submit" bg="#006AB1" _hover={{ bg: "#006AD1" }} _active={{
                  bg: "#dddfe2", transform: "scale(0.98)", borderColor: "#bec3c9",
                }} padding={3} margin={3} size="lg"
                ><Text fontWeight="bold" fontSize={["lg", "xl", "2xl"]}>PayPal&nbsp;</Text> Donate</Button>
              </form>
              <Spacer />
              <Text fontWeight="bold" fontSize={["lg", "xl", "2xl"]}>Please mail your pledges to:</Text>
              <Text fontSize={["md", "lg", "xl"]}>Salvation Army Christmas Basket</Text>
              <Text fontSize={["md", "lg", "xl"]}>237 Illinois Ave SW</Text>
              <Text fontSize={["md", "lg", "xl"]}>PO Box 1362</Text>
              <Text fontSize={["md", "lg", "xl"]}>Huron SD, 57350-1362</Text>
              <Text fontWeight="bold" fontSize={["lg", "xl", "2xl"]}>Please write "Christmas Basket" on the check.</Text>
            </Flex>
          </Box>
        </Center>
      </SimpleGrid>
      <Buttons />
    </Grid>
  </Box>
)