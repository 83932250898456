import { Box, Grid, Spacer, Flex } from "@chakra-ui/react"
import React from "react"
import { Buttons } from "../components/Buttons"
import { LivePlayer } from "../components/LivePlayer"
import { Title } from "../components/Title"


export const Home = () => (
    <Box textAlign="center" fontSize="xl" bgGradient="linear(to-tr, #b92b27, #1565C0)">
        <Grid minH="100vh" p={3}>
            <Title />
            <Flex flexDirection="row">
                <Spacer />
                <Box flex={["18", "6", "3"]}>
                    <LivePlayer />
                </Box>
                <Spacer />
            </Flex>
            <Buttons />
        </Grid>
    </Box>
)