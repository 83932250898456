import React from "react"
import { Text } from "@chakra-ui/react"

export const Title = () => (
    <Text
        //bgGradient="linear(to-l, #2F7336, #AA3A38)"
        //bgGradient="linear(to-r, #FC354C, #0ABFBC)"
        //bgClip="text"
        fontSize={["2xl", "4xl", "5xl"]}
        fontWeight="extrabold"
    >
        The Huron Salvation Army Christmas Basket
    </Text>
)