import { AspectRatio } from "@chakra-ui/react";
import { AiFillPlayCircle } from "react-icons/ai"
import React from "react";
import ReactPlayer from "react-player/lazy";


export const LivePlayer = () => (
    <AspectRatio maxWidth="100%" ratio={16 / 9} >
        <ReactPlayer
            url='https://www.youtube.com/watch?v=grPze6DfdKM'
            controls
            // playsinline
            playing
            playIcon={<AiFillPlayCircle color='black' size={80}/>}
            light='https://christmas-basket.pages.dev/ChristmasBasketThumbnail.png'
            width='100%'
            height='100%'
        />
    </AspectRatio>
)

