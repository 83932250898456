import { Accordion, AccordionButton, AccordionIcon, AccordionItem, AccordionPanel, Box, Center, Grid, SimpleGrid, Table, Tbody, Td, Text, Th, Thead, Tr } from "@chakra-ui/react"
import React from "react"
import { Buttons } from "../components/Buttons"
import { Title } from "../components/Title"


export const Schedule = () => (

    <Box textAlign="center" fontSize="xl" bgGradient="linear(to-tr, #b92b27, #1565C0)">
        <Grid minH="100vh" p={3}>
            <Title />
            <SimpleGrid columns={1} spacing={10} p={[5, 10, 25]} position="relative" zIndex="10">
                <Center>
                    <Box bg="red.500" borderRadius="lg" width={["100%", "95%", "60%"]}>
                        <Text fontSize={["xl", "2xl", "3xl"]} fontWeight="extrabold" p={5}>Schedule</Text>
                        <Accordion allowToggle marginBottom={3}>
                            <AccordionItem>
                                <h2>
                                    <AccordionButton>
                                        <Box flex="1" textAlign="left">
                                            <Text fontSize="xl" fontWeight="bold" p={3}>Monday November 29, 2021</Text>
                                        </Box>
                                        <AccordionIcon />
                                    </AccordionButton>
                                </h2>
                                <AccordionPanel pb={4}>
                                    <Table variant="unstyled">
                                        <Thead>
                                            <Tr>
                                                <Th>Time</Th>
                                                <Th>Group</Th>
                                            </Tr>
                                        </Thead>
                                        <Tbody>
                                            <Tr>
                                                <Td>6:30 PM</Td>
                                                <Td>Huron Middle School 7th & 8th Grade Choir</Td>
                                            </Tr>
                                            <Tr>
                                                <Td>6:55 PM</Td>
                                                <Td>Huron High School Concert Choir</Td>
                                            </Tr>
                                            <Tr>
                                                <Td>7:20 PM</Td>
                                                <Td>Huron High School Madrigals</Td>
                                            </Tr>
                                            <Tr>
                                                <Td>7:45 PM</Td>
                                                <Td>Huron High School Concert Band</Td>
                                            </Tr>
                                            <Tr>
                                                <Td>8:15 PM</Td>
                                                <Td>Huron High School Jazz Band</Td>
                                            </Tr>
                                        </Tbody>
                                    </Table></AccordionPanel>
                            </AccordionItem>
                            <AccordionItem>
                                <h2>
                                    <AccordionButton>
                                        <Box flex="1" textAlign="left">
                                            <Text fontSize="xl" fontWeight="bold" p={3}>Tuesday November 30, 2021</Text>
                                        </Box>
                                        <AccordionIcon />
                                    </AccordionButton>
                                </h2>
                                <AccordionPanel pb={4}>
                                    <Table variant="unstyled">
                                        <Thead>
                                            <Tr>
                                                <Th>Time</Th>
                                                <Th>Group</Th>
                                            </Tr>
                                        </Thead>
                                        <Tbody>
                                            <Tr>
                                                <Td>6:30 PM</Td>
                                                <Td>James Valley Christian School Choir</Td>
                                            </Tr>
                                            <Tr>
                                                <Td>6:55 PM</Td>
                                                <Td>James Valley Christian School Concert Band</Td>
                                            </Tr>
                                            <Tr>
                                                <Td>7:20 PM</Td>
                                                <Td>Wolsey-Wessington School Choir</Td>
                                            </Tr>
                                            <Tr>
                                                <Td>7:45 PM</Td>
                                                <Td>Wolsey-Wessington School Band</Td>
                                            </Tr>
                                        </Tbody>
                                    </Table></AccordionPanel>
                            </AccordionItem>
                            <AccordionItem>
                                <h2>
                                    <AccordionButton>
                                        <Box flex="1" textAlign="left">
                                            <Text fontSize="xl" fontWeight="bold" p={3}>Wedensday December 1, 2021</Text>
                                        </Box>
                                        <AccordionIcon />
                                    </AccordionButton>
                                </h2>
                                <AccordionPanel pb={4}>
                                    <Table variant="unstyled">
                                        <Thead>
                                            <Tr>
                                                <Th>Time</Th>
                                                <Th>Group</Th>
                                            </Tr>
                                        </Thead>
                                        <Tbody>
                                            <Tr>
                                                <Td>6:30 PM</Td>
                                                <Td>Mount Calvary Church Choir</Td>
                                            </Tr>
                                            <Tr>
                                                <Td>6:55 PM</Td>
                                                <Td>Bethesda Church Group</Td>
                                            </Tr>
                                            <Tr>
                                                <Td>7:20 PM</Td>
                                                <Td>Holy Trinity Choir of Angels</Td>
                                            </Tr>
                                            <Tr>
                                                <Td>7:45 PM</Td>
                                                <Td>First United Methodist Church Choirs</Td>
                                            </Tr>
                                            <Tr>
                                                <Td>8:15 PM</Td>
                                                <Td>First United Methodist Church Praise Team</Td>
                                            </Tr>
                                        </Tbody>
                                    </Table></AccordionPanel>
                            </AccordionItem>
                            <AccordionItem>
                                <h2>
                                    <AccordionButton>
                                        <Box flex="1" textAlign="left">
                                            <Text fontSize="xl" fontWeight="bold" p={3}>Thursday December 2, 2021</Text>
                                        </Box>
                                        <AccordionIcon />
                                    </AccordionButton>
                                </h2>
                                <AccordionPanel pb={4}>
                                    <Table variant="unstyled">
                                        <Thead>
                                            <Tr>
                                                <Th>Time</Th>
                                                <Th>Group</Th>
                                            </Tr>
                                        </Thead>
                                        <Tbody>
                                            <Tr>
                                                <Td>6:30 PM</Td>
                                                <Td>Huron High School Orchestra</Td>
                                            </Tr>
                                            <Tr>
                                                <Td>6:55 PM</Td>
                                                <Td>Huron Middle School Honors Orchestra</Td>
                                            </Tr>
                                            <Tr>
                                                <Td>7:20 PM</Td>
                                                <Td>Huron Chamber Orchestra</Td>
                                            </Tr>
                                            <Tr>
                                                <Td>7:45 PM</Td>
                                                <Td>Huron Middle School 7th & 8th Grade Band</Td>
                                            </Tr>
                                            <Tr>
                                                <Td>8:15 PM</Td>
                                                <Td>Huron Municipal Band</Td>
                                            </Tr>
                                        </Tbody>
                                    </Table></AccordionPanel>
                            </AccordionItem>
                        </Accordion>
                    </Box>
                </Center>
            </SimpleGrid>
            <Buttons />
        </Grid>
    </Box>
)