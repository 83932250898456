import * as React from "react"
import { ChakraProvider } from "@chakra-ui/react"
import theme from "./components/theme"
import { Home } from "./pages/Home"
import { Route } from "wouter"
import { Donate } from "./pages/Donate"
import { Schedule } from "./pages/Schedule"
import Snowfall from "react-snowfall"
import { Landing } from "./pages/Landing"


export const App = () => (
  <ChakraProvider theme={theme}>
    <Snowfall snowflakeCount={150} />
    <Route path="/" component={Home} />
    <Route path="/donate" component={Donate} />
    <Route path="/schedule" component={Schedule} />
    <Route path="/landing" component={Landing} />
  </ChakraProvider>
)


