import { Box, Grid, Spacer, Flex, Text, Link, Button, Center, SimpleGrid, Accordion, AccordionButton, AccordionIcon, AccordionItem, AccordionPanel, Table, Tbody, Td, Th, Thead, Tr, LightMode } from "@chakra-ui/react"
import React from "react"
import { FaFacebook, FaYoutube } from "react-icons/fa"
import { LivePlayer } from "../components/LivePlayer"
import { Title } from "../components/Title"

export const Landing = () => (
    <Box textAlign="center" fontSize="xl" bgGradient="linear(to-tr, #b92b27, #1565C0)">
        <Grid minH="100vh" p={3}>
            <Title />
            <Flex flexDirection="row" marginTop={10} marginBottom={10}>
                <Spacer />
                <Box flex={["18", "6", "3"]}>
                    <LivePlayer />
                </Box>
                <Spacer />
            </Flex>
            <SimpleGrid columns={1} spacing={10} p={[5, 10, 25]} position="relative" zIndex="10">
                <Center>
                    <Box bg="red.500" p={3} borderRadius="lg" width={["100%", "95%", "60%"]}>
                        <Flex flexDirection="column" p={[5, 10]}>
                            <Text fontWeight="extrabold" fontSize={["lg", "xl", "2xl"]}>Pledges and Donations:</Text>
                            <Spacer />
                            <Text fontSize={["md", "lg", "xl"]}>Pledges can be made online through PayPal or by phone:&nbsp;
                                <Link color="#000000" href="tel:605-554-0553" fontWeight="bold">(605) 554-0553</Link>
                                &nbsp;or by email:&nbsp;
                                <Link color="#000000" href="mailto:pledge@christmasbasket.us" fontWeight="bold">pledge@christmasbasket.us</Link>
                                &nbsp;
                            </Text>
                            <Spacer />
                            <form target="_blank" action="https://www.paypal.com/cgi-bin/webscr" method="post">
                                <input type="hidden" name="cmd" value="_s-xclick" />
                                <input type="hidden" name="hosted_button_id" value="X6GH4LSH5Z2DA" />
                                {/* <input type="image" src="Paypal.png" name="submit" alt="PayPal - The safer, easier way to pay online!" width="50%"/> */}
                                <Button type="submit" bg="#006AB1" _hover={{ bg: "#006AD1" }} _active={{
                                    bg: "#dddfe2", transform: "scale(0.98)", borderColor: "#bec3c9",
                                }} padding={3} margin={3} size="lg"
                                ><Text fontWeight="bold" fontSize={["lg", "xl", "2xl"]}>PayPal&nbsp;</Text> Donate</Button>
                            </form>
                            <Spacer />
                            <Text fontWeight="bold" fontSize={["lg", "xl", "2xl"]}>Please mail your pledges to:</Text>
                            <Text fontSize={["md", "lg", "xl"]}>Salvation Army Christmas Basket</Text>
                            <Text fontSize={["md", "lg", "xl"]}>237 Illinois Ave SW</Text>
                            <Text fontSize={["md", "lg", "xl"]}>PO Box 1362</Text>
                            <Text fontSize={["md", "lg", "xl"]}>Huron SD, 57350-1362</Text>
                            <Text fontWeight="bold" fontSize={["lg", "xl", "2xl"]}>Please write "Christmas Basket" on the check.</Text>
                        </Flex>
                    </Box>
                </Center>
            </SimpleGrid>
            <SimpleGrid columns={1} spacing={10} p={[5, 10, 25]} position="relative" zIndex="10">
                <Center>
                    <Box bg="red.500" borderRadius="lg" width={["100%", "95%", "60%"]}>
                        <Text fontSize={["xl", "2xl", "3xl"]} fontWeight="extrabold" p={5}>Schedule</Text>
                        <Accordion allowToggle marginBottom={3}>
                            <AccordionItem>
                                <h2>
                                    <AccordionButton>
                                        <Box flex="1" textAlign="left">
                                            <Text fontSize="xl" fontWeight="bold" p={3}>Monday November 29, 2021</Text>
                                        </Box>
                                        <AccordionIcon />
                                    </AccordionButton>
                                </h2>
                                <AccordionPanel pb={4}>
                                    <Table variant="unstyled">
                                        <Thead>
                                            <Tr>
                                                <Th>Time</Th>
                                                <Th>Group</Th>
                                            </Tr>
                                        </Thead>
                                        <Tbody>
                                            <Tr>
                                                <Td>6:30 PM</Td>
                                                <Td>Huron Middle School 7th & 8th Grade Choir</Td>
                                            </Tr>
                                            <Tr>
                                                <Td>6:55 PM</Td>
                                                <Td>Huron High School Concert Choir</Td>
                                            </Tr>
                                            <Tr>
                                                <Td>7:20 PM</Td>
                                                <Td>Huron High School Madrigals</Td>
                                            </Tr>
                                            <Tr>
                                                <Td>7:45 PM</Td>
                                                <Td>Huron High School Concert Band</Td>
                                            </Tr>
                                            <Tr>
                                                <Td>8:15 PM</Td>
                                                <Td>Huron High School Jazz Band</Td>
                                            </Tr>
                                        </Tbody>
                                    </Table></AccordionPanel>
                            </AccordionItem>
                            <AccordionItem>
                                <h2>
                                    <AccordionButton>
                                        <Box flex="1" textAlign="left">
                                            <Text fontSize="xl" fontWeight="bold" p={3}>Tuesday November 30, 2021</Text>
                                        </Box>
                                        <AccordionIcon />
                                    </AccordionButton>
                                </h2>
                                <AccordionPanel pb={4}>
                                    <Table variant="unstyled">
                                        <Thead>
                                            <Tr>
                                                <Th>Time</Th>
                                                <Th>Group</Th>
                                            </Tr>
                                        </Thead>
                                        <Tbody>
                                            <Tr>
                                                <Td>6:30 PM</Td>
                                                <Td>James Valley Christian School Choir</Td>
                                            </Tr>
                                            <Tr>
                                                <Td>6:55 PM</Td>
                                                <Td>James Valley Christian School Concert Band</Td>
                                            </Tr>
                                            <Tr>
                                                <Td>7:20 PM</Td>
                                                <Td>Wolsey-Wessington School Choir</Td>
                                            </Tr>
                                            <Tr>
                                                <Td>7:45 PM</Td>
                                                <Td>Wolsey-Wessington School Band</Td>
                                            </Tr>
                                        </Tbody>
                                    </Table></AccordionPanel>
                            </AccordionItem>
                            <AccordionItem>
                                <h2>
                                    <AccordionButton>
                                        <Box flex="1" textAlign="left">
                                            <Text fontSize="xl" fontWeight="bold" p={3}>Wedensday December 1, 2021</Text>
                                        </Box>
                                        <AccordionIcon />
                                    </AccordionButton>
                                </h2>
                                <AccordionPanel pb={4}>
                                    <Table variant="unstyled">
                                        <Thead>
                                            <Tr>
                                                <Th>Time</Th>
                                                <Th>Group</Th>
                                            </Tr>
                                        </Thead>
                                        <Tbody>
                                            <Tr>
                                                <Td>6:30 PM</Td>
                                                <Td>Mount Calvary Church Choir</Td>
                                            </Tr>
                                            <Tr>
                                                <Td>6:55 PM</Td>
                                                <Td>Bethesda Church Group</Td>
                                            </Tr>
                                            <Tr>
                                                <Td>7:20 PM</Td>
                                                <Td>Holy Trinity Choir of Angels</Td>
                                            </Tr>
                                            <Tr>
                                                <Td>7:45 PM</Td>
                                                <Td>First United Methodist Church Choirs</Td>
                                            </Tr>
                                            <Tr>
                                                <Td>8:15 PM</Td>
                                                <Td>First United Methodist Church Praise Team</Td>
                                            </Tr>
                                        </Tbody>
                                    </Table></AccordionPanel>
                            </AccordionItem>
                            <AccordionItem>
                                <h2>
                                    <AccordionButton>
                                        <Box flex="1" textAlign="left">
                                            <Text fontSize="xl" fontWeight="bold" p={3}>Thursday December 2, 2021</Text>
                                        </Box>
                                        <AccordionIcon />
                                    </AccordionButton>
                                </h2>
                                <AccordionPanel pb={4}>
                                    <Table variant="unstyled">
                                        <Thead>
                                            <Tr>
                                                <Th>Time</Th>
                                                <Th>Group</Th>
                                            </Tr>
                                        </Thead>
                                        <Tbody>
                                            <Tr>
                                                <Td>6:30 PM</Td>
                                                <Td>Huron High School Orchestra</Td>
                                            </Tr>
                                            <Tr>
                                                <Td>6:55 PM</Td>
                                                <Td>Huron Middle School Honors Orchestra</Td>
                                            </Tr>
                                            <Tr>
                                                <Td>7:20 PM</Td>
                                                <Td>Huron Chamber Orchestra</Td>
                                            </Tr>
                                            <Tr>
                                                <Td>7:45 PM</Td>
                                                <Td>Huron Middle School 7th & 8th Grade Band</Td>
                                            </Tr>
                                            <Tr>
                                                <Td>8:15 PM</Td>
                                                <Td>Huron Municipal Band</Td>
                                            </Tr>
                                        </Tbody>
                                    </Table></AccordionPanel>
                            </AccordionItem>
                        </Accordion>
                    </Box>
                </Center>
            </SimpleGrid>
            <Center>
                <LightMode>
                    <SimpleGrid columns={2} spacing={4} padding={5}>
                        <Button colorScheme="facebook" leftIcon={<FaFacebook />} variant="solid" onClick={() => window.open("https://www.facebook.com/SAChristmasBasket/")} size={"lg"}>
                            Facebook
                        </Button>
                        <Button colorScheme="red" leftIcon={<FaYoutube />} onClick={() => window.open("https://www.youtube.com/channel/UC_Gugt356Xy0Jh4d6_UJkkQ")} size={"lg"}>
                            YouTube
                        </Button>
                    </SimpleGrid>
                </LightMode>
            </Center>
        </Grid>
    </Box>
)
